@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary: rgba(6, 102, 230, 1);
}

/* essentials */
* {
	font-family: "Roboto Condensed", sans-serif;
}

html,
body,
#root {
	@apply h-full w-full relative text-dark-100;
	height: 100% !important;
}

.container {
	max-width: 1280px !important;
}

/* form elements */
.field .input,
button,
.button {
	@apply p-2 text-sm border;
}

button,
.button {
	@apply flex items-center justify-center gap-1;
}

button:hover,
button:active {
	@apply opacity-80;
}

button.primary,
.primary button {
	@apply bg-blue-100 text-white;
}

button.red,
.red button {
	@apply bg-red-100 text-white;
}

.button.red {
	@apply text-red-100  bg-red-10;
}

.button.yellow {
	@apply text-yellow-100 bg-yellow-10;
}

.button.green {
	@apply text-green-100 bg-green-10;
}

.button.blue {
	@apply text-blue-100 bg-blue-10;
}

.field .label {
	@apply text-xs;
}

.field .info {
	@apply text-xs;
	font-size: 10px;
}

button:disabled {
	@apply cursor-not-allowed bg-opacity-70;
}

textarea {
	vertical-align: top !important;
}

/* form errors */
.field .error,
.field:has(.error) .info,
.field:has(.error) .autocomplete__input-container,
.field:has(.error) .autocomplete__placeholder {
	@apply text-red-100;
}

.field:has(.error) .input,
.field:has(.error) .input::placeholder {
	@apply border-red-100 text-red-100;
}

/* react select */
.autocomplete__control {
	border-radius: 0 !important;
	border-color: #e5e7eb !important;
}

.autocomplete__input-container,
.autocomplete__placeholder,
.autocomplete__menu,
.autocomplete__single-value,
.autocomplete__multi-value,
.selectable-container {
	@apply text-sm;
}

.autocomplete__menu {
	z-index: 2 !important;
}

/* react toggle */
.react-toggle--checked .react-toggle-track {
	background-color: var(--primary) !important;
}
.react-toggle--checked .react-toggle-thumb {
	border-color: var(--primary) !important;
}
.swal2-confirm {
	background-color: var(--primary) !important;
}
.react-toggle--focus .react-toggle-thumb {
	box-shadow: none !important;
}

/* rich text */
.rdw-editor-main {
	@apply border px-2;
}

.rdw-editor-main {
	min-height: 335px;
}

/* sweetalert2 */
.swal2-styled {
	box-shadow: none !important;
}
.swal2-icon {
	border-color: var(--primary) !important;
	color: var(--primary) !important;
}

/* custom class */
.middle {
	@apply flex items-center justify-center;
}

h1 {
	@apply uppercase;
}

h1.bordered {
	@apply border-b mb-2 pb-2;
}

table td {
	@apply p-2 whitespace-pre-line;
}

.selectable-container {
	@apply flex flex-wrap gap-2;
}

.selectable-container .selectable-item {
	@apply p-2 border;
}
svg {
	@apply flex-shrink-0;
}

.glass {
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}

.home.active,
.all.active {
	@apply text-white bg-dark-100;
}

.metro.active {
	@apply text-white bg-red-100;
}

.brt.active {
	@apply text-white bg-yellow-100;
}

.cablecar.active,
.transparency.active,
.articles.active,
.about.active {
	@apply text-white bg-blue-100;
}

.greengate.active,
.survey.active {
	@apply text-white bg-green-100;
}

.active-scroll {
	@apply w-32;
}

.metro strong,
.red strong {
	@apply text-red-100;
}

.brt strong,
.yellow strong {
	@apply text-yellow-100;
}

.cablecar strong,
.blue strong {
	@apply text-blue-100;
}

.greengate strong,
.green strong {
	@apply text-green-100;
}

footer {
	background: linear-gradient(#0366e6, #023980);
}

.about strong {
	@apply text-black font-normal;
}

.dashboard .card {
	@apply shadow-md bg-white glass;
}

.dashboard .grid .big {
	@apply text-3xl font-bold;
}

.dashboard .grid .title {
	@apply uppercase;
}
